import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseStudiesService } from '../../services/caseStudies.service';
import { CaseStudies, CaseStudyItem, Process } from '../../interfaces/caseStudies.interface';
import { orderBy } from 'lodash';

@Component({
    selector: 'app-case-studies',
    templateUrl: './case-studies.component.html',
    styleUrls: ['./case-studies.component.less']
})
export class CaseStudiesComponent implements OnInit {

    public key:string = '';
    public title:string = '';
    public description:string = '';
    public nameCaseStudy:string = '';
    public process:Process[] = [];
    public caseStudyItem:CaseStudyItem[] = [
        {
            title: '',
            description: '',
            icon: undefined,
        }
    ];
    public dataSetCaseStudies:any[] = [];
    public menuVisible:boolean = false;
    public menu:any[] = [];

    constructor(
        public _service:CaseStudiesService,
        public activatedRoute:ActivatedRoute,
    ) {}

    ngOnInit() {
        this.getCaseStudies();
    }

    public onChangeItem(key:string):void {
        this.closeMenu();
    }

    public getCaseStudy(records: any[]) {
        this.activatedRoute.params.subscribe(params => {
            const url:string = decodeURIComponent(params['id']);
            const type:string = url.split('/')[0];
            const title:string = url.split('/')[1];
            const record = records.filter(item => (item.type === type && item.title === title));
            const key:string = record[0].key;

            this._service.getCaseStudy(key).subscribe((record:CaseStudies) => {
                if (record) {
                    this.key = key;
                    this.title = record.title || '';
                    this.description = record.description || '';
                    this.nameCaseStudy = record.nameCaseStudy || '';
                    this.process = record.process || [];
                    this.caseStudyItem = record.caseStudyItem || [];
                }
            });
        });
    }

    public getCaseStudies(): void {
        const { _service } = this;
        let { dataSetCaseStudies, menu } = this;

        _service.getCaseStudies().subscribe(records => {
            if (records) {
                Object.keys(records).forEach((key, index) => {
                    const caseStury:CaseStudies = records[key];

                    dataSetCaseStudies.push({
                        title: caseStury.title,
                        type: caseStury.type,
                        nameCaseStudy: caseStury.nameCaseStudy,
                        description: caseStury.description,
                        key: key,
                        uri_enc: encodeURIComponent(`${caseStury.type}/${caseStury.title}`),
                    });
                });

                this.getCaseStudy(dataSetCaseStudies);

                dataSetCaseStudies = orderBy(dataSetCaseStudies, ['type']);
                let list:any = dataSetCaseStudies.map(item => item.type);
                list = Array.from(new Set(list));

                for (const item of list) {
                    menu.push({
                        name: item,
                        open: true,
                        data: dataSetCaseStudies.filter(record => record.type === item),
                    });
                }
            }
        });
    }

    public closeMenu():void {
        this.menuVisible = false;
    }

    public openMenu():void {
        this.menuVisible = !this.menuVisible;
    }

}
