import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { SocialNetworks } from "../interfaces/social-networks.interface";
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class SocialNetworksService {

    public socialListURL:string = "https://geostrategy-3de81.firebaseio.com/social.json";
    public socialURL:string = "https://geostrategy-3de81.firebaseio.com/social";
    public defaultKey:string = "-LPV1JcIdJq4htKF3EJJ";

    constructor(public http:Http) { }

    public newSocial(social:any):any {

        let body = JSON.stringify( social );
        const headers = new Headers({
            'Content-Type':'application/json',
        });

        return this.http.post( this.socialListURL, body, {headers}).map(res => res.json());
    }

    public editSocial(social, key$:string = this.defaultKey):any {

        let body = JSON.stringify( social );
        let headers = new Headers({
            'Content-Type':'application/json',
        });

        let url:string = `${this.socialURL}/${key$}.json`;

        return this.http.put( url, body, {headers}).map(res => res.json());
    }

    public deleteSocial(key$:string = this.defaultKey):any {
        let url:string = `${this.socialURL}/${key$}.json`;

        return this.http.delete(url).map(res => res.json());
    }

    public getSocialList():any {
        return this.http.get(this.socialListURL).map(res => res.json());
    }

    public getSocial(key$:string = this.defaultKey):any {
        let url:string = `${this.socialURL}/${key$}.json`;

        return this.http.get(url).map(res => res.json());
    }

}
