import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { SocialNetworksService } from './services/social-networks.service';
import { ContactInformationService } from './services/contact-information.service';
import { ContactInformation } from "./interfaces/contact-information.interface";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

    public title:string = 'geostrategypartners';
    public bodyStyle:{} = {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1'
    };
    public dataSocialNetworks = {
        facebook: '#',
        twitter: '#',
        google: '#',
        instagram: '#',
        linkedin: '#'
    };
    public contactData:ContactInformation = {
        title: '',
        description: '',
        name: '',
        address: '',
        phone: '',
        cellPhone: '',
        email: '',
        emailForm: '',
        main: false,
    };
    public phone:string;
    public email:string;

    constructor(
        public _contact:ContactInformationService,
        public _social: SocialNetworksService
    ) {
        this.getSocialData();
        this.getContactData();
    }

    public getSocialData():void {
        this._social.getSocial().subscribe( data => {
            if (data) {
                Object.keys(this.dataSocialNetworks).forEach(field => {
                    this.dataSocialNetworks[field] = this.getURL(data, field);
                });
            }
        });
    }

    public getContactData():void {
        this._contact.getContactList().subscribe(records => {
            if (records) {
                Object.keys(records).forEach(key => {
                    const record:ContactInformation = records[key];

                    if (record.main) {
                        this.contactData = record;
                    }
                });

                this.phone = `tel:${this.getPhone(this.contactData.phone)}`;
                this.email = `mailto:${this.contactData.email}`;
            }
        });
    }

    private getPhone(phone:string):string {
        const newPhone = phone.match(/\d/g).join('');

        return newPhone;
    }

    public getURL(data: {}, field: string) {
        if (data[field].active) {
            return data[field].url;
        }

        return '#';
    }

    public scrollToElement():void {
        const element:any = document.querySelector("#gsp-content");

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

}
