import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(public _auth:AuthService) {}

    public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {

        if (this._auth.isAuthenticated()) {
            return true;
        }

        return false;
    }

}
