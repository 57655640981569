import { Component, OnInit } from '@angular/core';
import { JobsOpportunitiesService } from '../../services/jobs-opportunities.service';
import { JobsOpportunities } from '../../interfaces/jobs-opportunities.interface';

@Component({
  selector: 'app-jobs-opportunities',
  templateUrl: './jobs-opportunities.component.html',
  styleUrls: ['./about-detail.component.less']
})
export class JobsOpportunitiesComponent implements OnInit {

    public title:string = '';
    public description:string = '';
    public buttonText:string = '';
    public url:string = '';
    public vacanciesAvailable:boolean = false;
    public message:string = '';
    public showMessage:boolean = false;

    constructor(public _service:JobsOpportunitiesService) {}

    ngOnInit() {
        this.getData();
    }

    public getData(): void {
        this._service.getOpportunityList().subscribe((data:JobsOpportunities) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:JobsOpportunities = data[key];

                    this.title = record.title || '';
                    this.description = record.description || '';
                    this.buttonText = record.buttonText || '';
                    this.url = record.url || '';
                    this.vacanciesAvailable = record.vacanciesAvailable || false;
                    this.message = record.message || '';

                    return;
                });
            }
        });
    }

    public apply() {
        // https://geostrategy.zohorecruit.com/recruit/ViewJob.na?digest=omDaigzIhXKB2nAoHcL6Ss.TaiuuNMEj6PeGIQ9MQX0-&embedsource=CareerSite
        const { url, vacanciesAvailable } = this;

        if (vacanciesAvailable) {
            window.open(url, '_blank');
        }
        else {
            this.showMessage = true;
        }
    }

}
