import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../../services/about.service';
import { ContactInformationService } from '../../../services/contact-information.service';
import { ServiceService } from '../../../services/service.service';
import { SocialNetworksService } from '../../../services/social-networks.service';
import { Service } from "../../../interfaces/service.interface";
import { About } from "../../../interfaces/about.interface";
import { ContactInformation } from "../../../interfaces/contact-information.interface";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

    public dataSetService = [];
    public dataSetContact:ContactInformation = {
        title: "",
        description: "",
        name: "",
        address: "",
        phone: "",
        cellPhone: "",
        email: "",
        emailForm: "",
        main: true
    };
    public dataSetAbout = {
        key: '',
        footer: '',
    };
    public fullYear:number;
    public dataSocialNetworks = {
        facebook: '#',
        twitter: '#',
        google: '#',
        instagram: '#',
        linkedin: '#'
    };

    constructor(
        public _about:AboutService,
        public _contact:ContactInformationService,
        public _service:ServiceService,
        public _social: SocialNetworksService
    ) {
        const date = new Date();
        this.fullYear = date.getFullYear();
    }

    ngOnInit() {
        this.getAboutData();
        this.getContactData();
        this.getServiceData();
        this.getSocialData();
    }

    private getSocialData():void {
        this._social.getSocial().subscribe( data => {
            if (data) {
                Object.keys(this.dataSocialNetworks).forEach(field => {
                    this.dataSocialNetworks[field] = this.getURL(data, field);
                });
            }
        });
    }

    private getURL(data: {}, field: string) {
        if (data[field].active) {
            return data[field].url;
        }

        return '#';
    }

    public getContactData():void {
        this._contact.getContactList().subscribe(records => {
            if (records) {

                Object.keys(records).forEach(key => {
                    const record:ContactInformation = records[key];

                    if (record.main) {
                        this.dataSetContact = record;
                    }
                });
            }
        });
    }

    public getAboutData():void {
        this._about.getAboutList().subscribe((data:About) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:About = data[key];

                    this.dataSetAbout = {
                        key: key,
                        footer: record.footer,
                    };

                    return;
                });
            }
        });
    }

    private getServiceData():void {
        this.dataSetService = [];

        this._service.getServicesList().subscribe(records => {
            if (records) {

                Object.keys(records).forEach(key => {
                    const length = this.dataSetService.length;

                    if (length < 5) {
                        const service: Service = records[key];

                        this.dataSetService.push({
                            title: service.title,
                            type: service.type,
                            key: key,
                            uri_enc: encodeURIComponent(service.title),
                        });
                    }
                });
            }
        });
    }

}
