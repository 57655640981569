import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Service } from '../interfaces/service.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

    public serviceListURL:string = "https://geostrategy-3de81.firebaseio.com/services.json";
    public serviceURL:string = "https://geostrategy-3de81.firebaseio.com/services";

    constructor(public http:Http) { }

    public newService(service:Service):any {

        let serv:Service = {
            title: service.title,
            description: service.description,
            detail: service.detail,
            type: service.type,
            icon: service.icon,
            file: service.file,
        };
        let body = JSON.stringify( serv );
        const headers = new Headers({
            'Content-Type':'application/json',
        });

        return this.http.post( this.serviceListURL, body, {headers}).map(res => res.json());
    }

    public editService(service:Service, key$:string):any {
        const serv:Service = {
            title: service.title,
            description: service.description,
            detail: service.detail,
            type: service.type,
            icon: service.icon,
            file: service.file,
        };
        const body = JSON.stringify( serv );
        const headers = new Headers({
            'Content-Type':'application/json',
        });

        const url:string = `${this.serviceURL}/${key$}.json`;
        return this.http.put( url, body, {headers}).map(res => res.json());
    }

    public deleteService(key$:string):any {
        let url:string = `${this.serviceURL}/${key$}.json`;
        return this.http.delete(url).map(res => res.json());
    }

    public getServicesList():any {
        return this.http.get(this.serviceListURL).map(res => res.json());
    }

    public getService(key$:string):any {
        let url:string = `${this.serviceURL}/${key$}.json`;
        return this.http.get(url).map(res => res.json());
    }

}
