import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HomeService } from '../../../services/home.service';
import { NzModalRef, NzModalService, NzMessageService } from 'ng-zorro-antd';
import { Home } from '../../../interfaces/home.interface';
import { clone, pullAt } from 'lodash';
import { firebase } from '../../../firebase';

@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
  styleUrls: ['./home-form.component.less']
})
export class HomeFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<string>();
    @Input()
    public editorConfig:AngularEditorConfig;
    @Input()
    public showSection:boolean = false;
    public key:string = '';
    public data:Home = {
        section1: {
            title: '',
            subtitle: '',
            description: '',
            buttonText: '',
            image: '',
        },
        section2: {
            title1: '',
            detail1: '',
            buttonText1: '',
            image1: '',
            redirect1: '',
            title2: '',
            detail2: '',
            buttonText2: '',
            image2: '',
            redirect2: '',
            title3: '',
            detail3: '',
            buttonText3: '',
            image3: '',
            redirect3: '',
        },
        section3: {
            title1: '',
            detail1: '',
            image1: '',
            title2: '',
            detail2: '',
            image2: '',
        },
        section4: {
            title: '',
            items: [],
        },
        section5: {
            title: '',
        }
    };
    public section:string;
    public showModal = {
        section2: false,
        section4: false,
    };
    public indexToEdit:number;
    public isNew:boolean = true;
    public section2 = {
        title: '',
        description: '',
        buttonText: '',
    };
    public section4 = {
        title: '',
        description: '',
        icon: '',
    };
    public files:Files = {
        section1: {
            file: null,
            isLoading: false,
        },
        section2: {
            file1: null,
            file2: null,
            file3: null,
            isLoading: false,
        },
        section3: {
            file1: null,
            file2: null,
            isLoading: false,
        },
    };
    private sectionToSave:string;
    // ICON
    public isVisibleModalIcon:boolean = false;
    public iconSelected:string;

    constructor(
        public modal: NzModalService,
        public _service:HomeService
    ) {}

    public onChangeFile(event:any, section:string):void {
        const { target: { files }} = event;

        if (section === 'section1') {
            this.files.section1.file = files;
        } else if (section === 'section2.1') {
            this.files.section2.file1 = files;
        } else if (section === 'section2.2') {
            this.files.section2.file2 = files;
        } else if (section === 'section2.3') {
            this.files.section2.file3 = files;
        } else if (section === 'section3.1') {
            this.files.section3.file1 = files;
        } else if (section === 'section3.2') {
            this.files.section3.file2 = files;
        }
    }

    private getFiles(sectionPath:string): any[] {
        const { files } = this;
        const filesToUpload:any[] = [];

        if (sectionPath === 'section1') {
            filesToUpload.push({ section: 'section1', file: files.section1.file });
        } else if (sectionPath === 'section2') {
            filesToUpload.push({ section: 'section2.1', file: files.section2.file1 });
            filesToUpload.push({ section: 'section2.2', file: files.section2.file2 });
            filesToUpload.push({ section: 'section2.3', file: files.section2.file3 });
        } else if (sectionPath === 'section3') {
            filesToUpload.push({ section: 'section3.1', file: files.section3.file1 });
            filesToUpload.push({ section: 'section3.2', file: files.section3.file2 });
        }

        return filesToUpload
    }

    private setIsLoading(section?:string):void {
        const { files } = this;

        if (section === 'section1') {
            files.section1.isLoading = true;
        } else if (section === 'section2.1' || section === 'section2.2' || section === 'section2.3') {
            files.section2.isLoading = true;
        } else if (section === 'section3.1' || section === 'section3.2') {
            files.section3.isLoading = true;
        } else {
            files.section1.isLoading = false;
            files.section2.isLoading = false;
            files.section3.isLoading = false;
        }
    }

    public async upload(sectionPath:string) {
        const filesToUpload:any[] = this.getFiles(sectionPath);

        for await (let item of filesToUpload) {
            const { file, section } = item;

            if (file !== null) {
                await this.uploadFile(sectionPath, section, file).then(result => {
                    return result;
                });
            }
        }

        this.onSave();
    }

    private uploadFile(sectionPath, section, file) {
        const { data, files } = this;
        const storage = firebase.storage().ref(`home/${sectionPath}/${file[0].name}`);
        const upload = storage.put(file[0]);

        this.setIsLoading(section);

        return new Promise((resolve, reject) => {
            upload.on(
                "state_changed",
                function progress(snapshot) {
                    // const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                function error() {
                    this.setIsLoading();
                    this.showMessage.emit({ type: 'error', message: 'Error uploading file' });
                },
                function complete() {
                    upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        if (section === 'section1') {
                            data.section1.image = downloadURL;
                            files.section1.file = null;
                        } else if (section === 'section2.1') {
                            data.section2.image1 = downloadURL;
                            files.section2.file1 = null;
                        } else if (section === 'section2.2') {
                            data.section2.image2 = downloadURL;
                            files.section2.file2 = null;
                        } else if (section === 'section2.3') {
                            data.section2.image3 = downloadURL;
                            files.section2.file3 = null;
                        } else if (section === 'section3.1') {
                            data.section3.image1 = downloadURL;
                            files.section3.file1 = null;
                        } else if (section === 'section3.2') {
                            data.section3.image2 = downloadURL;
                            files.section3.file2 = null;
                        }

                        resolve(true);
                    });
                }
            );
        });
    }

    ngOnInit() {
        this.getData();
    }

    public getData():void {
        this._service.getHomeList().subscribe((data:Home) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Home = data[key];

                    this.key = key;
                    this.data = {
                        section1: record.section1,
                        section2: record.section2,
                        section3: record.section3,
                        section4: record.section4,
                        section5: {
                            title: (record.section5) ? (record.section5.title || '') : '',
                        },
                    };

                    return;
                });
            }
        });
    }

    public onSave():void {
        const { _service, key, data, section } = this;
        const dataSet:Home = data;
        this.replaceLink(dataSet);
        if (!key) {
            _service.newHome(dataSet).subscribe(data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
                this.setIsLoading();
            });
        } else {
            _service.editHome(dataSet, key).subscribe(data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
                this.setIsLoading();
            });
        }

        this.showModal[section] = false;
    }

    private saved():void {
        this.getData();
        this.showMessage.emit('success');
        this.setIsLoading();
    }

    private replaceLink(data):void {
        const searchRegExp = /target=\"_blank\"/gi;
        const { section1: { description }} = data;

        data.section1.description = description.replace(searchRegExp, '');
    }

    public onSaveSection4():void {
        const { section4, isNew, indexToEdit } = this;

        if (isNew) {
            const newData = {
                title: section4.title || '',
                description: section4.description || '',
                icon: section4.icon || '',
            };

            if (this.data.section4.items) {
                this.data.section4.items.push(newData);
            } else {
                this.data.section4.items = [
                    newData
                ];
            }

        } else {
            this.data.section4.items[indexToEdit] = {
                title: section4.title || '',
                description: section4.description || '',
                icon: section4.icon || '',
            };
        }

        this.onSave();
    }

    public onShowModal(show:boolean=false, section:string, newEdit:boolean=true, index?:number):void {
        this.section = section;
        this.indexToEdit = index;

        if (show) {
            const { data } = this;

            this.isNew = newEdit;

            if (newEdit) {
                this.clear();
            } else {
                if (section === 'section2') {
                    this.section2 = clone(data.section2[index]);
                }
                else if (section === 'section4') {
                    this.section4 = clone(data.section4.items[index]);
                }
            }
        }

        this.showModal[section] = show;
    }

    public clear():void {
        const { section } = this;

        if (section === 'section2') {
            this.section2 = {
                title: '',
                description: '',
                buttonText: '',
            };
        }
        else if (section === 'section4') {
            this.section4 = {
                title: '',
                description: '',
                icon: '',
            };
        }
    }

    public onShowConfirm(index:number):void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.onDelete(index),
            nzCancelText: 'No',
        });
    }

    public onDelete(index:number) {
        const { section } = this;

        if (section === 'section2') {
            pullAt(this.data.section2, [index]);
        }
        else if (section === 'section4') {
            pullAt(this.data.section4.items, [index]);
        }

        this.onSave();
    }

    public showModalIcon(show:boolean = false):void {
        const { showModal: { section4 }, data, indexToEdit, isNew } = this;
        this.isVisibleModalIcon = show;

        if (show && !isNew) {
            if (section4) {
                this.iconSelected = data.section4.items[indexToEdit].icon;
            }
        }
    }

    public getIcon(icon:string):void {
        const { showModal } = this;

        this.showModalIcon(false);

        if (showModal.section4) {
            this.section4.icon = icon;
        }
    }

}

type Files = {
    section1: {
        file: any,
        isLoading: boolean,
    },
    section2: {
        file1: any,
        file2: any,
        file3: any,
        isLoading: boolean,
    },
    section3: {
        file1: any,
        file2: any,
        isLoading: boolean,
    },
};
