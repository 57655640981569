import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { About } from '../interfaces/about.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

    public aboutListURL:string = "https://geostrategy-3de81.firebaseio.com/aboutSection.json";
    public aboutURL:string = "https://geostrategy-3de81.firebaseio.com/aboutSection";
    public headers = new Headers({
        'Content-Type':'application/json',
    });

    constructor(public http:Http) {}

    public newAbout(data:About):any {
        const { http, aboutListURL, headers } = this;
        const about:About = {
            titleSection1: data.titleSection1,
            description: data.description,
            titleSection2: data.titleSection2,
            principles: data.principles,
            titleSection3: data.titleSection3,
            helps: data.helps,
            titleSection4: data.titleSection4,
            reasons: data.reasons,
            footer: data.footer,
        };
        const body = JSON.stringify( about );

        return http.post( aboutListURL, body, { headers }).map(res => res.json());
    }

    public editAbout(data:About, key:string):any {
        const { http, aboutURL, headers } = this;
        const about:About = {
            titleSection1: data.titleSection1,
            description: data.description,
            titleSection2: data.titleSection2,
            principles: data.principles,
            titleSection3: data.titleSection3,
            helps: data.helps,
            titleSection4: data.titleSection4,
            reasons: data.reasons,
            footer: data.footer,
        };
        const body = JSON.stringify( about );
        const url:string = `${aboutURL}/${key}.json`;

        return http.put( url, body, { headers }).map(res => res.json());
    }

    public deleteAbout(key:string):any {
        const { http, aboutURL } = this;
        const url:string = `${aboutURL}/${key}.json`;

        return http.delete(url).map(res => res.json());
    }

    public getAbout(key:string):any {
        const { http, aboutURL } = this;
        const url:string = `${aboutURL}/${key}.json`;

        return http.get(url).map(res => res.json());
    }

    public getAboutList():any {
        const { http, aboutListURL } = this;

        return http.get(aboutListURL).map(res => res.json());
    }

}
