import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

    public url:string = "https://geostrategy-3de81.firebaseio.com";

    constructor(public http:Http) {}

    public POST(path:string, record:{}):any {
        const body:string = JSON.stringify( record );
        const urlFinal = `${this.url}/${path}`;

        return this.http.post( urlFinal, body, {headers}).map(res => res.json());
    }

    public PUT(path:string, record:{}):any {
        const body = JSON.stringify( record );
        const urlFinal = `${this.url}/${path}`;

        return this.http.put( urlFinal, body, {headers}).map(res => res.json());
    }

    public GET(path:string):any {
        const urlFinal = `${this.url}/${path}`;

        return this.http.get(urlFinal).map(res => res.json());
    }

    public DELETE(path:string):any {
        const urlFinal = `${this.url}/${path}`;

        return this.http.delete(urlFinal).map(res => res.json());
    }

}

const headers:Headers = new Headers({
    'Content-Type':'application/json',
});
