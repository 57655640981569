import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { CaseStudies } from '../interfaces/caseStudies.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {

    public caseStudiesListURL:string = "https://geostrategy-3de81.firebaseio.com/caseStudies.json";
    public caseStudiesURL:string = "https://geostrategy-3de81.firebaseio.com/caseStudies";

    constructor(public http:Http) { }

    public newCaseStudy(data:CaseStudies):any {
        const record:CaseStudies = {
            title: data.title || '',
            type: data.type || '',
            description: data.description || '',
            nameCaseStudy: data.nameCaseStudy || '',
            caseStudyItem: data.caseStudyItem || [],
            process: data.process || [],
        };
        const body = JSON.stringify( record );
        const headers = new Headers({
            'Content-Type':'application/json',
        });

        return this.http.post( this.caseStudiesListURL, body, {headers}).map(res => res.json());
    }

    public editCaseStudy(data:CaseStudies, key$:string):any {
        const record:CaseStudies = {
            title: data.title,
            type: data.type,
            description: data.description,
            nameCaseStudy: data.nameCaseStudy,
            caseStudyItem: data.caseStudyItem || [],
            process: data.process || [],
        };
        const body = JSON.stringify( record );
        const headers = new Headers({
            'Content-Type':'application/json',
        });
        const url:string = `${this.caseStudiesURL}/${key$}.json`;

        return this.http.put( url, body, {headers}).map(res => res.json());
    }

    public deleteCaseStudy(key$:string):any {
        const url:string = `${this.caseStudiesURL}/${key$}.json`;

        return this.http.delete(url).map(res => res.json());
    }

    public getCaseStudies():any {
        return this.http.get(this.caseStudiesListURL).map(res => res.json());
    }

    public getCaseStudy(key$:string):any {
        const url:string = `${this.caseStudiesURL}/${key$}.json`;

        return this.http.get(url).map(res => res.json());
    }

}
