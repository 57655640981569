import { Component, OnInit } from '@angular/core';
import { Service } from "../../interfaces/service.interface";
import { ServiceService } from '../../services/service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.less']
})
export class ServiceComponent implements OnInit {

    public menuVisible:boolean = false;
    public openMap = {
        sub1: true,
        sub2: true,
    };

    public title:string = '';
    public description:string = '';
    public detail:string = '';
    public file:string = '';
    public content:string = '';
    public key$:string = '';
    public dataPrimaryService = [];
    public dataAncillaryService = [];
    public formData = {
        name: '',
        email: '',
        subject: '',
        message: '',
    };
    public hasAncillaryService:boolean = false;

    public tabs:number[] = [ 1, 2, 3 ];

    constructor(
        public _serviceServices:ServiceService,
        public activatedRoute:ActivatedRoute
    ) {}

    ngOnInit() {
        this.getServiceList();
    }

    public getService(services: any[]) {
        this.activatedRoute.params.subscribe(params => {
            const title = decodeURIComponent(params['id']);
            const record = services.filter(item => item.title === title);
            this.key$ = record[0].key;

            this._serviceServices.getService(this.key$).subscribe(service => {
                if (service) {
                    this.title = service.title;
                    this.formData.subject = service.title;
                    this.description = service.description;
                    this.file = `url('${service.file}')`;
                    this.detail = service.detail;
                }
            });
        });
    }

    public closeMenu():void {
        this.menuVisible = false;
    }

    public openMenu():void {
        this.menuVisible = !this.menuVisible;
    }

    public getServiceList():void {
        const records = [];

        this._serviceServices.getServicesList().subscribe(record => {
            if (record) {
                Object.keys(record).forEach(key => {
                    const service:Service = record[key];

                    records.push({
                        title: service.title,
                        type: service.type,
                        key: key,
                        uri_enc: encodeURIComponent(service.title),
                    });
                });

                this.dataPrimaryService = records.filter(item => item.type === 'Primary');
                this.dataAncillaryService = records.filter(item => item.type === 'Ancillary');

                if (this.dataAncillaryService.length > 0) {
                    this.hasAncillaryService = true;
                }

                this.getService(records);
            }
        });
    }

}
