import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { environment } from '../../environment';
/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

// Routes
import { APP_ROUTING } from './app.routes';

// Componets
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SectionHeaderComponent } from './components/shared/section-header/section-header.component';
import { LogoComponent } from './components/shared/logo/logo.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { WorkUsComponent } from './components/contact/work-us.component';
import { ServiceComponent } from './components/service/service.component';
import { AboutDetailComponent } from './components/about-detail/about-detail.component';
import { TestimonialsComponent } from './components/about-detail/testimonials.component';
import { JobsOpportunitiesComponent } from './components/about-detail/jobs-opportunities.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';

import { JobsOpportunitiesFormComponent } from './components/control-panel/jobs-opportunities-form/jobs-opportunities-form.component';
import { ContactFormComponent } from './components/control-panel/contact-form/contact-form.component';
import { CaseStudiesFormComponent } from './components/control-panel/case-studies/case-studies-form.component';
import { ServiceFormComponent } from './components/control-panel/service-form/service-form.component';
import { TestimonialsFormComponent } from './components/control-panel/testimonials-form/testimonials-form.component';
import { AboutUsFormComponent } from './components/control-panel/about-us-form/about-us-form.component';
import { HomeFormComponent } from './components/control-panel/home-form/home-form.component';

// Components Imported
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
// import { NgbdCarousel } from './components/shared/carousel/carousel.component';

// Services
import { RequestsService } from "./services/requests.service";
import { CaseStudiesService } from "./services/caseStudies.service";
import { ReasonsService } from "./services/reasons.service";
import { AboutService } from "./services/about.service";
import { JobsOpportunitiesService } from "./services/jobs-opportunities.service";
import { ServiceService } from "./services/service.service";
import { TestimonialsService } from "./services/testimonials.service";
import { ContactInformationService } from "./services/contact-information.service";
import { AuthService } from "./services/auth.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { SocialNetworksService } from "./services/social-networks.service";
import { HomeService } from "./services/home.service";
import { EmailClientService } from "./services/email-client.service";
import { SafeHtmlPipe } from "./pipe/safe-html.pipe";

import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { IconsComponent } from './components/shared/icons/icons.component';
import { ThankPageComponent } from './components/contact/thank-page/thank-page.component';

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        // NgbdCarousel,
        SectionHeaderComponent,
        LogoComponent,
        HomeComponent,
        AboutComponent,
        ServicesComponent,
        ContactComponent,
        WorkUsComponent,
        ServiceComponent,
        AboutDetailComponent,
        TestimonialsComponent,
        JobsOpportunitiesComponent,
        LoginFormComponent,
        ControlPanelComponent,
        ContactFormComponent,
        CaseStudiesFormComponent,
        CaseStudiesComponent,
        IconsComponent,
        ServiceFormComponent,
        TestimonialsFormComponent,
        AboutUsFormComponent,
        SafeHtmlPipe,
        JobsOpportunitiesFormComponent,
        HomeFormComponent,
        ThankPageComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        HttpModule,
        NgZorroAntdModule,
        NgbModule,
        AngularEditorModule,
        ColorPickerModule,
        TooltipModule.forRoot(),
        APP_ROUTING,
    ],
    providers: [
        RequestsService,
        CaseStudiesService,
        ReasonsService,
        AboutService,
        ServiceService,
        TestimonialsService,
        ContactInformationService,
        AuthService,
        AuthGuardService,
        SocialNetworksService,
        HomeService,
        EmailClientService,
        { provide: NZ_I18N, useValue: en_US }
    ],
    bootstrap: [
        AppComponent,
        // NgbdCarousel,
    ],
    // exports: [NgbdCarousel],
})
export class AppModule { }
