import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { JobsOpportunities } from '../interfaces/jobs-opportunities.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class JobsOpportunitiesService {

    public jobsOportunitiesListURL:string = "https://geostrategy-3de81.firebaseio.com/jobsOportunities.json";
    public jobsOportunitiesURL:string = "https://geostrategy-3de81.firebaseio.com/jobsOportunities";
    public headers = new Headers({
        'Content-Type':'application/json',
    });

    constructor(public http:Http) {}

    public newOpportunity(data:JobsOpportunities):any {
        const { http, jobsOportunitiesListURL, headers } = this;
        const record:JobsOpportunities = {
            title: data.title,
            description: data.description,
            buttonText: data.buttonText,
            url: data.url,
            vacanciesAvailable: data.vacanciesAvailable || false,
            message: data.message || '',
        };
        const body = JSON.stringify( record );

        return http.post( jobsOportunitiesListURL, body, { headers }).map(res => res.json());
    }

    public editOpportunity(data:JobsOpportunities, key:string):any {
        const { http, jobsOportunitiesURL, headers } = this;
        const record:JobsOpportunities = {
            title: data.title,
            description: data.description,
            buttonText: data.buttonText,
            url: data.url,
            vacanciesAvailable: data.vacanciesAvailable || false,
            message: data.message || '',
        };
        const body = JSON.stringify( record );
        const url:string = `${jobsOportunitiesURL}/${key}.json`;

        return http.put( url, body, { headers }).map(res => res.json());
    }

    public deleteOpportunity(key:string):any {
        const { http, jobsOportunitiesURL } = this;
        const url:string = `${jobsOportunitiesURL}/${key}.json`;

        return http.delete(url).map(res => res.json());
    }

    public getOpportunity(key:string):any {
        const { http, jobsOportunitiesURL } = this;
        const url:string = `${jobsOportunitiesURL}/${key}.json`;

        return http.get(url).map(res => res.json());
    }

    public getOpportunityList():any {
        const { http, jobsOportunitiesListURL } = this;

        return http.get(jobsOportunitiesListURL).map(res => res.json());
    }

}
