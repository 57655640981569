import { Component, OnInit } from '@angular/core';
import { ContactInformationService } from '../../services/contact-information.service';
import { ContactInformation } from "../../interfaces/contact-information.interface";
import { EmailClientService } from '../../services/email-client.service';
import { Email } from "../../interfaces/email.interface";
import { NzModalService } from 'ng-zorro-antd';
import S from 'string';
import { Router } from '@angular/router';

@Component({
  selector: 'app-work-us',
  templateUrl: './work-us.component.html',
  styleUrls: ['./contact.component.less']
})
export class WorkUsComponent implements OnInit {

    public dataSetContact:ContactInformation = {
        title: '',
        description: '',
        name: '',
        address: '',
        phone: '',
        cellPhone: '',
        email: '',
        emailForm: '',
        main: true
    };
    public phone:string;
    public form:any = {
        name: '',
        email: '',
        company: '',
        subject: '',
        message: '',
    };
    public showRequiredFields:boolean = false;

    constructor(
        public _contact:ContactInformationService,
        public _email:EmailClientService,
        private modalService: NzModalService,
        private router:Router,
    ) {}

    ngOnInit() {
        this.getContactData();
    }

    private getPhone(phone:string):string {
        const newPhone = phone.match(/\d/g).join('');

        return newPhone;
    }

    public getContactData():void {
        this._contact.getContactList().subscribe(records => {
            if (records) {
                Object.keys(records).forEach(key => {
                    const record:ContactInformation = records[key];

                    if (record.main) {
                        this.dataSetContact = record;
                    }
                });

                this.phone = `tel:${this.getPhone(this.dataSetContact.phone)}`;
            }
        });
    }

    public async sendMessage() {
        const { form: { name, email, company, subject, message }, dataSetContact: { emailForm } } = this;

        if (this.isEmpty(emailForm)) {
            this.createMessage('error', 'A destination mail has not been provided.');

            return;
        }
        if (this.isEmpty([name, email, company, subject, message])) {
            this.showRequiredFields = true;
            return;
        }

        this.showRequiredFields = false;
        const data:Email = {
            name: name || '',
            email: email || '',
            company: company || '',
            subject: subject || '',
            message: message || '',
            to: emailForm || '',
            date: new Date().toISOString(),
        };
        const response = await this._email.sendMessage(data);

        if (response) {
            this.form = {
                name: '',
                email: '',
                company: '',
                subject: '',
                message: '',
            };
            this.router.navigate(['/thank-page']);
        }
    }

    private createMessage(type: string, message?: string): void {
        if (type === 'success') {
            this.modalService.success({
                nzTitle: (message || 'Email sent successfully'),
            });
        }
        else {
            this.modalService.error({
                nzTitle: (message || 'Something went wrong'),
            });
        }
    }

    private isEmpty(value:(string[] | string)) {
        if (Array.isArray(value)) {
            for (const item of value) {
                if (S(item).isEmpty()) {
                    return true;
                }
            }

            return false;
        }

        return S(value).isEmpty();
    }

    public validateStatus(field:string):string {
        const { showRequiredFields, form } = this;

        if (showRequiredFields && this.isEmpty(form[field])) {
            return 'error';
        }

        return '';
    }

}
