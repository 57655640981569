import { Component, OnInit } from '@angular/core';
import { TestimonialsService } from '../../services/testimonials.service';
import { Testimonials, TestimonialsSection } from '../../interfaces/testimonials.interface';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./about-detail.component.less']
})
export class TestimonialsComponent implements OnInit {

    public dataSet:Testimonials[];
    public avatar:string = 'https://img.pngio.com/-profile-png-700_720.png';
    public testimonialsSection:TestimonialsSection = {
        description: '',
    };

    constructor(public _service:TestimonialsService) {}

    ngOnInit() {
        this.getList();
        this.getDescription();
    }

    public getList():void {
        this.dataSet = [];

        this._service.getTestimonialsList().subscribe((data:Testimonials) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Testimonials = data[key];

                    this.dataSet.push({
                        author: record.author,
                        employeeType: record.employeeType,
                        testimony: record.testimony,
                        icon: record.icon || '',
                        file: record.file,
                        key: key,
                    });
                });

            }
        });
    }

    public getDescription():void {
        this._service.getTestimonialsSection().subscribe(data => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:TestimonialsSection = data[key];

                    this.testimonialsSection = {
                        key: key,
                        description: record.description,
                    };

                    return;
                });
            }
        });
    }

}
