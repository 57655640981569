import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { About } from "../../interfaces/about.interface";
import { Home } from "../../interfaces/home.interface";
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {

    public dataSet:any = {
        section1: {
            title: '',
            subtitle: '',
            description: '',
            buttonText: '',
        },
        section2: {
            title1: '',
            detail1: '',
            title2: '',
            detail2: '',
            title3: '',
            detail3: '',
        },
        section3: {
            title1: '',
            detail1: '',
            image1: "url('/assets/img/about_1.png')",
            title2: '',
            detail2: '',
            image2: "url('/assets/img/about_2.png')",
        },
        section4: {
            title: '',
            items: [],
        },
    };
    public slideshowData = [
        {
            title: 'GSN Research has a team of targeting professionals',
            description: "Our team specializes in this complex list-building process. There is no magic, it's just hard work and we do it well. Contact us today for your target list building needs.",
            image: '',
            path: ['#contact'],
            redirect: '',
            buttonText: 'Get a quote',
            showContent: true,
        },
        {
            title: 'Experienced Professionals',
            description: 'Contact us today if you need experienced professional moderators for in-depth telephone interviews, online focus groups and other services.',
            image: '',
            path: ['service', '-M4mkHGOYtuTrIMcKBcw'],
            redirect: '',
            buttonText: 'Read more',
            showContent: true,
        },
        {
            title: 'Reasons Clients call us',
            description: 'Our clients can depend on our high-integrity approach.',
            image: '',
            path: ['about-us'],
            redirect: '',
            buttonText: 'Read more',
            showContent: true,
        },
    ];
    public firstIndex:number = 0;
    private interval:any;

    constructor(
        private router:Router,
        public _service:HomeService,
    ) {}

    ngOnInit() {
        this.getData();
    }

    public getData(): void {
        this._service.getHomeList().subscribe((data:Home) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Home = data[key];

                    this.dataSet = {
                        section1: record.section1,
                        section2: record.section2,
                        section3: {
                            title1: record.section3.title1,
                            detail1: record.section3.detail1,
                            image1: `url('${record.section3.image1}')`,
                            title2: record.section3.title2,
                            detail2: record.section3.detail2,
                            image2: `url('${record.section3.image2}')`,
                        },
                        section4: record.section4,
                    };

                    if (this.dataSet.section2) {
                        const data = this.dataSet.section2;

                        this.slideshowData[0].title = data.title1;
                        this.slideshowData[0].description = data.detail1;
                        this.slideshowData[0].buttonText = data.buttonText1;
                        this.slideshowData[0].redirect = data.redirect1;
                        this.slideshowData[0].image = data.image1;
                        this.slideshowData[1].title = data.title2;
                        this.slideshowData[1].description = data.detail2;
                        this.slideshowData[1].buttonText = data.buttonText2;
                        this.slideshowData[1].redirect = data.redirect2;
                        this.slideshowData[1].image = data.image2;
                        this.slideshowData[2].title = data.title3;
                        this.slideshowData[2].description = data.detail3;
                        this.slideshowData[2].buttonText = data.buttonText3;
                        this.slideshowData[2].redirect = data.redirect3;
                        this.slideshowData[2].image = data.image3;
                    }

                    return;
                });
            }
        });
    }

    public viewDetail():void {
        this.router.navigate(['/about-us']);
    }

    public redirect(redirect:string) {
        window.open(redirect, "_self");
        // if (path[0].includes('#')) {
        //     const element = document.querySelector(path[0]);
        //
        //     if (element) {
        //         element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //     }
        // } else {
        //     this.router.navigate(path);
        // }
    }

}
