import { Injectable } from '@angular/core';
import { firebase, db } from '../firebase';
import { Email } from '../interfaces/email.interface';

@Injectable({
  providedIn: 'root'
})
export class EmailClientService {

    constructor() { }

    public sendMessage(data:Email) {
        const addDoc = db.collection('orders').add(data).then(ref => {
            if (ref.id) {
                return true;
            }

            return false;
        });

        return addDoc;
    }
}
