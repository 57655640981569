import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TestimonialsService } from '../../../services/testimonials.service';
import { Testimonials, TestimonialsSection } from '../../../interfaces/testimonials.interface';

import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-testimonials-form',
  templateUrl: './testimonials-form.component.html',
  styleUrls: ['./testimonials-form.component.less']
})
export class TestimonialsFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<string>();
    @Input()
    public showSection:boolean = false;
    public showModal:boolean = false;
    public isNew:boolean = true;
    public dataSet:Testimonials[];
    public testimony:Testimonials = {
        author: '',
        employeeType: '',
        testimony: '',
        file: '',
        icon: '',
        key: '',
    };
    public testimonialsSection:TestimonialsSection = {
        isNew: true,
        description: '',
    };
    // ICON
    public isVisibleModalIcon:boolean = false;
    public iconSelected:string;

    constructor(
        public _service:TestimonialsService,
        public modal: NzModalService,
    ) {}

    ngOnInit() {
        this.getTestimonialsList();
        this.getDescription();
    }

    public getTestimonialsList():void {
        this.dataSet = [];

        this._service.getTestimonialsList().subscribe((data:Testimonials) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Testimonials = data[key];

                    this.dataSet.push({
                        author: record.author,
                        employeeType: record.employeeType,
                        testimony: record.testimony,
                        icon: record.icon,
                        file: record.file,
                        key: key,
                    });
                });
            }
        });
    }

    public onShowModal(show:boolean=false, newEdit:boolean=true, key$?:string):void {
        if (show) {
            this.isNew = newEdit;
            this.showModalTestimonials(key$);
        }

        this.showModal = show;
    }

    public showModalTestimonials(key?:string):void {
        if (this.isNew) {
            this.clearTestimonials();
        } else {
            this._service.getTestimony(key).subscribe((record:Testimonials) => {
                if (record) {
                    this.testimony = {
                        author: record.author,
                        employeeType: record.employeeType,
                        testimony: record.testimony,
                        icon: record.icon,
                        file: record.file,
                        key: key,
                    };
                }
            });
        }
    }

    public clearTestimonials():void {
        this.testimony = {
            author: '',
            employeeType: '',
            testimony: '',
            icon: '',
            file: '',
            key: '',
        };
    }

    public onShowConfirm(key:string): void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.onDelete(key),
            nzCancelText: 'No',
        });
    }

    public onDelete(key:string) {
        this._service.deleteTestimony(key).subscribe(response => {
            if (response == null) {
                this.showMessage.emit('success');
                this.getTestimonialsList();
            } else {
                this.showMessage.emit('error');
            }
        });
    }

    public onSave():void {
        if (this.isNew) {
            this._service.newTestimony(this.testimony).subscribe( data => {
                this.getTestimonialsList();
                this.clearTestimonials();
                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            this._service.editTestimony(this.testimony, this.testimony.key).subscribe( data => {
                this.getTestimonialsList();
                this.clearTestimonials();
                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        }

        this.showModal = false;
    }

    public getDescription():void {
        this._service.getTestimonialsSection().subscribe(data => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:TestimonialsSection = data[key];

                    this.testimonialsSection = {
                        key: key,
                        isNew: false,
                        description: record.description,
                    };

                    return;
                });
            }
        });
    }

    public onSaveDescription():void {
        const { testimonialsSection } = this;

        if (testimonialsSection.isNew) {
            this._service.newTestimonialsSection(testimonialsSection).subscribe(data => {
                testimonialsSection.isNew = false;
                testimonialsSection.key = data.name;

                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            this._service.editTestimonialsSection(testimonialsSection, testimonialsSection.key).subscribe(data => {
                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        }
    }

    disabledSaveButton():boolean {
        const { testimonialsSection: { description }} = this;

        return description === null || description === undefined || description === '<br>' || description === '' || description.trim() === '<br>';
    }

    public showModalIcon(show:boolean = false):void {
        this.isVisibleModalIcon = show;

        if (show) {
            this.iconSelected = this.testimony.icon;
        }
    }

    public getIcon(icon:string):void {
        this.showModalIcon(false);
        this.testimony.icon = icon;
    }

}
