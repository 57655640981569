import { Component } from '@angular/core';

@Component({
    selector: 'app-logo',
    template: `
        <img class='logo' src="./assets/img/logo_white.png" alt="Logo">
    `,
    styles: [`
        img {
            width: 100%;
        }
    `]
})
export class LogoComponent {

    constructor() { }

}
