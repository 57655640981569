import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobsOpportunitiesService } from '../../../services/jobs-opportunities.service';
import { JobsOpportunities } from '../../../interfaces/jobs-opportunities.interface';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-jobs-opportunities-form',
  templateUrl: './jobs-opportunities-form.component.html',
  styleUrls: ['./jobs-opportunities-form.component.less']
})
export class JobsOpportunitiesFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<string>();
    @Input()
    public editorConfig:AngularEditorConfig;
    @Input()
    public showSection:boolean = false;
    public key:string = '';
    public title:string = '';
    public description:string = '';
    public buttonText:string = '';
    public url:string = '';
    public vacanciesAvailable:boolean = false;
    public message:string = '';

    constructor(public _service:JobsOpportunitiesService) { }

    ngOnInit() {
        this.getData();
    }

    public getData():void {
        this._service.getOpportunityList().subscribe((data:JobsOpportunities) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:JobsOpportunities = data[key];

                    this.key = key;
                    this.title = record.title || '';
                    this.description = record.description || '';
                    this.buttonText = record.buttonText || '';
                    this.url = record.url || '';
                    this.vacanciesAvailable = record.vacanciesAvailable || false;
                    this.message = record.message || '';

                    return;
                });
            }
        });
    }

    public onSave(): void {
        const { _service, key, description, title, buttonText, url, vacanciesAvailable, message } = this;
        const dataSet: JobsOpportunities = {
            title,
            description,
            buttonText,
            url,
            vacanciesAvailable,
            message,
        };

        if (!key) {
            _service.newOpportunity(dataSet).subscribe(data => {
                this.getData();
                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            _service.editOpportunity(dataSet, key).subscribe(data => {
                this.getData();
                this.showMessage.emit('success');
            }, error => {
                this.showMessage.emit('error');
            });
        }
    }

}
