import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContactInformation } from "../../../interfaces/contact-information.interface";
import { SocialNetworks } from "../../../interfaces/social-networks.interface";
import { ContactInformationService } from '../../../services/contact-information.service';
import { SocialNetworksService } from '../../../services/social-networks.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.less']
})
export class ContactFormComponent implements OnInit {

    public checked:boolean = true;
    public isNew:boolean = true;
    public showModalContact:boolean = false;
    public dataSet:ContactInformation[] = [];
    public dataSocialNetworks:any = {
        facebook: {
            original: 'https://www.facebook.com/',
            url: '',
            active: false,
        },
        twitter: {
            original: 'https://www.twitter.com/',
            url: '',
            active: false,
        },
        google: {
            original: 'https://plus.google.com/',
            url: '',
            active: false,
        },
        instagram: {
            original: 'https://www.instagram.com/',
            url: '',
            active: false,
        },
        linkedin: {
            original: 'https://www.linkedin.com/',
            url: '',
            active: false,
        },
    };
    public contact:ContactInformation = {
        title: '',
        description: '',
        name: '',
        address: '',
        urlMap: '',
        phone: '',
        cellPhone: '',
        email: '',
        emailForm: '',
        main: false,
        openingHours: '',
        key$: '',
    };

    @Output()
    public showMessage = new EventEmitter<string>();

    constructor(
        public _contactServices:ContactInformationService,
        public _social:SocialNetworksService,
        public modal: NzModalService,
    ) {}

    ngOnInit() {
        this.getContactList();
        this.getSocialData();
    }

    public clearContact():void {
        this.contact.title = '';
        this.contact.address = '';
        this.contact.urlMap = '';
        this.contact.phone = '';
        this.contact.cellPhone = '';
        this.contact.email = '';
        this.contact.emailForm = '';
        this.contact.main = false;
        this.contact.openingHours = '';
        this.contact.key$ = '';
    }

    public getContactList():void {
        this.dataSet = [];
        this._contactServices.getContactList().subscribe(contact => {
            if (contact) {
                Object.keys(contact).forEach((key) => {
                    const cont:ContactInformation = contact[key];

                    this.dataSet.push({
                        key$: key,
                        title: cont.title,
                        description: cont.description,
                        name: cont.name,
                        address: cont.address,
                        urlMap: cont.urlMap,
                        phone: cont.phone,
                        cellPhone: cont.cellPhone,
                        email: cont.email,
                        emailForm: cont.emailForm,
                        main: cont.main,
                        openingHours: cont.openingHours,
                    });
                });
            }
        });
    }

    public deleteContact(key$:string):void {
        this._contactServices.deleteContact(key$).subscribe(response => {
            if (response == null) {
                this.showMessage.emit('success');

                this.getContactList();
            } else {
                this.showMessage.emit('error');
            }
        });
    }

    public saveContact():void {
        if (this.isNew) {
            this._contactServices.newContact(this.contact).subscribe(data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            this._contactServices.editContact(this.contact, this.contact.key$).subscribe( data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        }

        this.showModalContact = false;
    }

    private saved() {
        this.getContactList();
        this.clearContact();
        this.showMessage.emit('success');
    }

    public updateAllChecked(key$:string, chxUpdateMain:boolean):void {
        for (let item of this.dataSet) {
            if (item.key$ === key$) {
                item.main = !chxUpdateMain;

                this.updateMainChecked(item, key$);
            }
            else {
                item.main = false;

                this.updateMainChecked(item, item.key$);
            }
        }
    }

    public updateMainChecked(data:ContactInformation, key$:string):void {
        this._contactServices.editContact(data, key$).subscribe( data => {
            this.showMessage.emit('success');
        }, error => {
            this.showMessage.emit('error');
        });
    }

    public showContactForm(show:boolean = false, isNew:boolean=false, key$?:string):void {
        this.showModalContact = show;
        this.isNew = isNew;
        if (this.isNew) {
            this.clearContact();
        } else {
            this._contactServices.getContact(key$).subscribe(contant => {
                if (contant) {
                    this.contact.title = contant.title;
                    this.contact.description = contant.description;
                    this.contact.name = contant.name;
                    this.contact.address = contant.address;
                    this.contact.urlMap = contant.urlMap;
                    this.contact.phone = contant.phone;
                    this.contact.cellPhone = contant.cellPhone;
                    this.contact.email = contant.email;
                    this.contact.emailForm = contant.emailForm;
                    this.contact.main = contant.main;
                    this.contact.openingHours = contant.openingHours;
                    this.contact.key$ = key$;
                }
            });
        }
    }

    // Social Networks

    public disabledField(field):void {
        if (!field.active) {
            field.url = '';
        }
    }

    public saveSocial():void {
        this._social.editSocial(this.dataSocialNetworks).subscribe(data => {
            this.showMessage.emit('success');
        }, error => {
            this.showMessage.emit('error');
        });
    }

    public getSocialData():void {
        this._social.getSocial().subscribe( data => {
            if (data) {
                this.dataSocialNetworks = data;
            }
        });
    }

    // Utils

    public onShowConfirm(key$:string): void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.deleteContact(key$),
            nzCancelText: 'No',
        });
    }

}
