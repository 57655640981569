import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceHome } from "../../interfaces/service.interface";
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.less']
})
export class ServicesComponent implements OnInit {

    public dataSetPrimaryService = [];
    public dataSetAncillaryService = [];
    public dataSetService:ServiceHome[] = [];
    public defaultIcon:string = 'fas fa-chart-pie';

    constructor(private router:Router, public _serviceService:ServiceService) {}

    ngOnInit() {
        this.getServiceList();
    }

    public viewService(title:string):void {
        const uri_enc:string = encodeURIComponent(title);

        this.router.navigate(['/service', uri_enc]);
    }

    public getServiceList():void {
        this.dataSetService = [];

        this._serviceService.getServicesList().subscribe(serviceData => {
            if (serviceData) {
                Object.keys(serviceData).forEach((key:string, index:number) => {
                    const record:ServiceHome = serviceData[key];

                    this.dataSetService.push({
                        key: key,
                        title: record.title,
                        description: record.description.substring(0, 459),
                        icon: record.icon || this.defaultIcon,
                        type: record.type,
                    });
                });

                this.dataSetPrimaryService = this.dataSetService.filter(item => item.type === 'Primary');
                this.dataSetAncillaryService = this.dataSetService.filter(item => item.type === 'Ancillary');
            }
        });
    }

}
