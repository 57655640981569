import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ContactInformation } from "../interfaces/contact-information.interface";

@Injectable({
  providedIn: 'root'
})
export class ContactInformationService {

    public contactListURL:string = "https://geostrategy-3de81.firebaseio.com/contact.json";
    public contactURL:string = "https://geostrategy-3de81.firebaseio.com/contact";

    constructor(public http:Http) {}

    public newContact(contact:ContactInformation):any {

        let cont:ContactInformation = {
            title: contact.title,
            description: contact.description,
            name: contact.name,
            address: contact.address,
            urlMap: contact.urlMap,
            phone: contact.phone,
            cellPhone: contact.cellPhone,
            email: contact.email,
            emailForm: contact.emailForm,
            main: contact.main,
            openingHours: contact.openingHours,
        };
        let body = JSON.stringify( cont );
        const headers = new Headers({
            'Content-Type':'application/json',
        });

        return this.http.post( this.contactListURL, body, {headers}).map(res => res.json());
    }

    public editContact(contact:ContactInformation, key$:string):any {

        let cont:ContactInformation = {
            title: contact.title,
            description: contact.description,
            name: contact.name,
            address: contact.address,
            urlMap: contact.urlMap,
            phone: contact.phone,
            cellPhone: contact.cellPhone,
            email: contact.email,
            emailForm: contact.emailForm,
            main: contact.main,
            openingHours: contact.openingHours,
        };
        let body = JSON.stringify( cont );
        let headers = new Headers({
            'Content-Type':'application/json',
        });

        let url:string = `${this.contactURL}/${key$}.json`;
        return this.http.put( url, body, {headers}).map(res => res.json());
    }

    public deleteContact(key$:string):any {
        let url:string = `${this.contactURL}/${key$}.json`;
        return this.http.delete(url).map(res => res.json());
    }

    public getContactList():any {
        return this.http.get(this.contactListURL).map(res => res.json());
    }

    public getContact(key$:string):any {
        let url:string = `${this.contactURL}/${key$}.json`;
        return this.http.get(url).map(res => res.json());
    }

    public checkMainItem():void {

    }

}
