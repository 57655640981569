import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Testimonials, TestimonialsSection } from '../interfaces/testimonials.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {

    public testimonialsListURL:string = "https://geostrategy-3de81.firebaseio.com/testimonials.json";
    public testimonyURL:string = "https://geostrategy-3de81.firebaseio.com/testimonials";
    public testimonialsSectionURL:string = "https://geostrategy-3de81.firebaseio.com/testimonialsSection";
    public testimonialsSectionListURL:string = "https://geostrategy-3de81.firebaseio.com/testimonialsSection.json";
    public headers:Headers = new Headers({
        'Content-Type':'application/json',
    });
    public avatar:string = 'https://cdn1.iconfinder.com/data/icons/avatar-3/512/Manager-48.png';

    constructor(public http:Http) { }

    public newTestimonialsSection(data:TestimonialsSection):any {
        const { http, testimonialsSectionListURL, headers } = this;
        const newRecord:TestimonialsSection = {
            description: data.description
        };
        const body = JSON.stringify( newRecord );

        return http.post( testimonialsSectionListURL, body, { headers }).map(res => res.json());
    }

    public editTestimonialsSection(data:TestimonialsSection, key:string):any {
        const { http, testimonialsSectionURL, headers } = this;
        const record:TestimonialsSection = {
            description: data.description
        };
        const body = JSON.stringify( record );
        const url:string = `${testimonialsSectionURL}/${key}.json`;

        return http.put( url, body, { headers }).map(res => res.json());
    }

    public getTestimonialsSection():any {
        return this.http.get(this.testimonialsSectionListURL).map(res => res.json());
    }

    public newTestimony(data:Testimonials):any {
        const { http, avatar, testimonialsListURL, headers } = this;
        const newRecord:Testimonials = {
            author: data.author,
            employeeType: data.employeeType,
            testimony: data.testimony,
            icon: data.icon,
            file: avatar,
        };
        const body = JSON.stringify( newRecord );

        return http.post( testimonialsListURL, body, { headers }).map(res => res.json());
    }

    public editTestimony(data:Testimonials, key:string):any {
        const { http, avatar, testimonyURL, headers } = this;
        const record:Testimonials = {
            author: data.author,
            employeeType: data.employeeType,
            testimony: data.testimony,
            icon: data.icon,
            file: avatar,
        };
        const body = JSON.stringify( record );
        const url:string = `${testimonyURL}/${key}.json`;

        return http.put( url, body, { headers }).map(res => res.json());
    }

    public deleteTestimony(key:string):any {
        const { testimonyURL, http } = this;
        const url:string = `${testimonyURL}/${key}.json`;

        return http.delete(url).map(res => res.json());
    }

    public getTestimonialsList():any {
        const { testimonialsListURL, http } = this;

        return http.get(testimonialsListURL).map(res => res.json());
    }

    public getTestimony(key:string):any {
        const { testimonyURL, http } = this;
        const url:string = `${testimonyURL}/${key}.json`;

        return http.get(url).map(res => res.json());
    }

}
