import { Injectable } from '@angular/core';
import { RequestsService } from './requests.service';
import { Reasons } from '../interfaces/reasons.interface';

@Injectable({
  providedIn: 'root'
})
export class ReasonsService {

    public urlReasonsList:string = "reasons.json";
    public urlReason:string = "reasons";

    constructor(public _requestsServices:RequestsService,) {}

    public insert(record:Reasons):any {
        const reason:Reasons = {
            title: record.title,
            description: record.description,
            type: record.type
        };

        return this._requestsServices.POST(this.urlReasonsList, reason);
    }

    public update(record:Reasons, key$:string):any {
        const url:string = `${this.urlReason}/${key$}.json`;
        const reason:Reasons = {
            title: record.title,
            description: record.description,
            type: record.type
        };

        return this._requestsServices.PUT(url, reason);
    }

    public remove(key$:string):any {
        const url:string = `${this.urlReason}/${key$}.json`;

        return this._requestsServices.DELETE(url);
    }

    public findOne(key$:string):any {
        const url:string = `${this.urlReason}/${key$}.json`;

        return this._requestsServices.GET(url);
    }

    public find():any {
        return this._requestsServices.GET(this.urlReasonsList);
    }

}
