import { Component, OnInit } from '@angular/core';
import { ContactInformationService } from '../../../services/contact-information.service';
import { ContactInformation } from '../../../interfaces/contact-information.interface';

@Component({
  selector: 'app-thank-page',
  templateUrl: './thank-page.component.html',
  styleUrls: ['./thank-page.component.less']
})
export class ThankPageComponent implements OnInit {

    public phone:string;
    public phoneLink:string;
    public email:string;

    constructor(private _contact:ContactInformationService,) {}

    ngOnInit() {
        this.getContactData();
    }

    public getContactData():void {
        this._contact.getContactList().subscribe(records => {
            if (records) {
                Object.keys(records).forEach(key => {
                    const record:ContactInformation = records[key];

                    if (record.main) {
                        this.phone = record.phone;
                        this.phoneLink = `tel:${this.getPhone(record.phone)}`;
                        this.email = record.email;
                    }
                });

            }
        });
    }

    private getPhone(phone:string):string {
        const newPhone = phone.match(/\d/g).join('');

        return newPhone;
    }

}
