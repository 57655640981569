import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { NgForm } from "@angular/forms";
import { NzMessageService } from 'ng-zorro-antd';

@Component({
    selector: 'app-control-panel',
    templateUrl: './control-panel.component.html',
    styleUrls: ['./control-panel.component.less']
})
export class ControlPanelComponent implements OnInit {

    public openMap:OpenMap = {
        home: true,
        caseStudies: false,
        about: false,
        jobsOpportunities: false,
        testimonials: false,
        services: false,
        contact: false,
    };
    public editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '150px',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'},
            {class: 'Rubik', name: 'Rubik'},
            {class: 'Rubik-Bold', name: 'Rubik-Bold'},
            {class: 'Poppins', name: 'Poppins'},
            {class: 'Poppins-Bold', name: 'Poppins-Bold'},
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        sanitize: false,
        toolbarPosition: 'top',
    };

    constructor(private message: NzMessageService) {}

    ngOnInit() {
        this.changeContent();
    }

    public changeContent(title:string = "Home"):void {
        this.openMap = {
            caseStudies: false,
            about: false,
            jobsOpportunities: false,
            testimonials: false,
            services: false,
            contact: false,
            home: false,
        };

        switch (title) {
            case "Home":
                this.openMap.home = true;
                break;
            case "Case Studies":
                this.openMap.caseStudies = true;
                break;
            case "About":
                this.openMap.about = true;
                break;
            case "Jobs Opportunities":
                this.openMap.jobsOpportunities = true;
                break;
            case "Testimonials":
                this.openMap.testimonials = true;
                break;
            case "Services":
                this.openMap.services = true;
                break;
            case "Contact":
                this.openMap.contact = true;
                break;
        }
    }

    public showMessage(data: (string | MessagePopup)): void {
        let textMessage:string;
        let typeMessage:string;

        if (typeof data === 'string') {
            typeMessage = data;

            if (typeMessage === 'success') {
                textMessage = 'Saved successfully';
            } else if (typeMessage === 'error') {
                textMessage = 'There was an error';
            }
        } else {
            const { type, message } = data;
            typeMessage = type;

            if (type === 'success') {
                textMessage = message ? message : 'Saved successfully';
            } else if (type === 'error') {
                textMessage = message ? message : 'There was an error';
            }
        }

        this.message.create(typeMessage, textMessage);
    }

}

type OpenMap = {
    caseStudies: boolean,
    about: boolean,
    jobsOpportunities: boolean,
    testimonials: boolean,
    services: boolean,
    contact: boolean,
    home: boolean,
};

type MessagePopup = {
    type: string,
    message?:string,
}
