import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ServiceService } from '../../../services/service.service';
import { Service } from "../../../interfaces/service.interface";
import { AuthService } from '../../../services/auth.service';
import { CaseStudiesService } from '../../../services/caseStudies.service';
import { CaseStudies, CaseStudyItem, Process } from '../../../interfaces/caseStudies.interface';
import { orderBy } from 'lodash';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {

    public dataSetPrimaryService = [];
    public dataSetAncillaryService = [];
    public firstPrimaryService = {};
    public firstAncillaryService = {};
    public hasAncillaryService:boolean = false;
    public dataSetCaseStudies:any[] = [];

    public fullService:any[] = [];
    public interviewing:any[] = [];
    public recruiting:any[] = [];
    public targeting:any[] = [];

    constructor(
        public _serviceServices:ServiceService,
        public _serviceCaseStudies:CaseStudiesService,
        public _auth:AuthService,
    ) {
        _auth.handleAuthentication();
    }

    ngOnInit() {
        this.getServiceList();
        this.getCaseStudies();
    }

    public login(): void {
        this._auth.login();
    }

    public logout(): void {
        this._auth.logout();
    }

    public getServiceList(): void {
        this._serviceServices.getServicesList().subscribe(records => {
            if (records) {
                const data = [];

                Object.keys(records).forEach(key => {
                    const service:Service = records[key];

                    data.push({
                        title: service.title,
                        type: service.type,
                        key: key,
                        uri_enc: encodeURIComponent(service.title),
                    });
                });

                this.dataSetPrimaryService = data.filter(item => item.type === 'Primary');
                this.dataSetAncillaryService = data.filter(item => item.type === 'Ancillary');
                this.firstPrimaryService = this.dataSetPrimaryService[0];

                if (this.dataSetAncillaryService.length > 0) {
                    this.hasAncillaryService = true;
                    this.firstAncillaryService = this.dataSetAncillaryService[0];
                }
            }
        });
    }

    private getCaseStudies(): void {
        const { _serviceCaseStudies } = this;
        let { dataSetCaseStudies } = this;

        _serviceCaseStudies.getCaseStudies().subscribe(records => {
            if (records) {
                Object.keys(records).forEach((key, index) => {
                    const caseStury:CaseStudies = records[key];

                    dataSetCaseStudies.push({
                        title: caseStury.title,
                        type: caseStury.type,
                        nameCaseStudy: caseStury.nameCaseStudy,
                        description: caseStury.description,
                        key: key,
                        uri_enc: encodeURIComponent(`${caseStury.type}/${caseStury.title}`),
                    });
                });

                this.fullService = dataSetCaseStudies.filter(item => item.type === 'Full Service');
                this.interviewing = dataSetCaseStudies.filter(item => item.type === 'Interviewing');
                this.recruiting = dataSetCaseStudies.filter(item => item.type === 'Recruiting');
                this.targeting = dataSetCaseStudies.filter(item => item.type === 'Targeting');
            }
        });
    }

}
