import firebase from 'firebase/app'
import 'firebase/storage';
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyC2s-Ii6T1vUatkEEy34YPTRoJ0h-mH-9M",
    authDomain: "geostrategy-3de81.firebaseapp.com",
    databaseURL: "https://geostrategy-3de81.firebaseio.com",
    projectId: "geostrategy-3de81",
    storageBucket: "geostrategy-3de81.appspot.com",
    messagingSenderId: "259639033025",
    appId: "1:259639033025:web:daa494caffea4d19d2a840"
};

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore();

export { db, firebase }
