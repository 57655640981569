import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FontAwesome } from "../../../interfaces/font-awesome.interface";
import { Icons } from '../../../utils/Helper';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.less']
})
export class IconsComponent implements OnInit {

    public icons:FontAwesome[] = [];
    @Input()
    public iconSelected:string;
    @Input()
    public isVisibleModalIcon:boolean = false;
    @Output()
    public getIcon = new EventEmitter<string>();
    @Output()
    public showModalIcon = new EventEmitter<boolean>();
    public search:string;

    constructor() {}

    ngOnInit() {
        const fontAwesomeIcons:Object = Icons.getIcons();

        Object.keys(fontAwesomeIcons).forEach((category:string) => {
            this.icons.push({
                category: category,
                icons: fontAwesomeIcons[category],
            });
        });
    }

    public selectIcon(icon:string):void {
        this.iconSelected = icon;
    }

    public handleOk(): void {
        this.getIcon.emit(this.iconSelected);
    }

    public onClose() {
        this.showModalIcon.emit(false);
    }

    public onChange():void {
        const { search } = this;
        const fontAwesomeIcons:Object = Icons.getIcons();

        this.icons = [];

        Object.keys(fontAwesomeIcons).forEach((category:string) => {
            if (category !== 'default') {
                const newList:string[] = fontAwesomeIcons[category].filter((item:string) => item.includes(search));

                if (newList.length > 0) {
                    this.icons.push({
                        category: category,
                        icons: newList,
                    });
                }
            }
        });
    }

}
