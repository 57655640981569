import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { WorkUsComponent } from './components/contact/work-us.component';
import { ThankPageComponent } from './components/contact/thank-page/thank-page.component';
import { ServiceComponent } from './components/service/service.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { AboutDetailComponent } from './components/about-detail/about-detail.component';
import { TestimonialsComponent } from './components/about-detail/testimonials.component';
import { JobsOpportunitiesComponent } from './components/about-detail/jobs-opportunities.component';
import { AuthGuardService } from "./services/auth-guard.service";

const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: HomeComponent },
  { path: 'services', component: HomeComponent },
  { path: 'contact', component: HomeComponent },
  {
      path: 'control-panel',
      component: ControlPanelComponent,
      canActivate: [ AuthGuardService ]
  },
  { path: 'service/:id', component: ServiceComponent },
  { path: 'case-studies/:id', component: CaseStudiesComponent },
  { path: 'about-us', component: AboutDetailComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'jobs-opportunities', component: JobsOpportunitiesComponent },
  { path: 'work-us', component: WorkUsComponent },
  { path: 'thank-page', component: ThankPageComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: false });
