import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../../../services/service.service';
import { Service } from "../../../interfaces/service.interface";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { firebase } from '../../../firebase';

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.less']
})
export class ServiceFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<(string | MessagePopup)>();
    @Input()
    public editorConfig:AngularEditorConfig;
    @Input()
    public showSection:boolean = false;
    public showModal:boolean = false;
    public isNew:boolean = true;
    public dataSet:Service[];
    public service:Service = {
        title: "",
        description: "",
        detail: "",
        file: "",
        icon: "",
        type: "Primary",
        key$: "",
    };
    public files:any = null;
    public isLoading:boolean = false;
    // ICON
    public isVisibleModalIcon:boolean = false;
    public iconSelected:string;

    constructor(
        public _service:ServiceService,
        public modal: NzModalService,
    ) {}

    ngOnInit() {
        this.getServiceList();
    }

    public getServiceList():void {
        this.dataSet = [];

        this._service.getServicesList().subscribe(data => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Service = data[key];

                    this.dataSet.push({
                        key$: key,
                        title: record.title,
                        description: record.description,
                        detail: record.detail,
                        type: record.type,
                        file: record.file,
                    });
                });
            }
        });
    }

    public onShowModal(show:boolean=false, newEdit:boolean=true, key$?:string):void {
        if (show) {
            this.isNew = newEdit;
            this.showModalService(key$);
        }

        this.showModal = show;
    }

    public showModalService(key$?:string):void {
        if (this.isNew) {
            this.clearService();
        } else {
            this._service.getService(key$).subscribe((service:Service) => {
                if (service) {
                    this.service = {
                        key$: key$,
                        title: service.title,
                        description: service.description,
                        detail: service.detail,
                        type: service.type,
                        icon: service.icon,
                        file: service.file,
                    };
                }
            });
        }
    }

    public clearService():void {
        this.service = {
            title: "",
            description: "",
            detail: "",
            type: "",
            key$: "",
        };
    }

    public onShowConfirm(key$:string): void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.deleteService(key$),
            nzCancelText: 'No',
        });
    }

    public deleteService(key$:string):void {
        this._service.deleteService(key$).subscribe(response => {
            if (response == null) {
                this.showMessage.emit('success');
                this.getServiceList();
            } else {
                this.showMessage.emit('error');
            }
        });
    }

    public onSave():void {
        const { service } = this;

        if (this.isNew) {
            this._service.newService(service).subscribe(data => {
                this.saved();
            }, error => {
                this.isLoading = false;
                this.showMessage.emit('error');
            });
        } else {
            this._service.editService(service, service.key$).subscribe(data => {
                this.saved();
            }, error => {
                this.isLoading = false;
                this.showMessage.emit('error');
            });
        }

        this.showModal = false;
    }

    private saved():void {
        this.getServiceList();
        this.clearService();
        this.showMessage.emit('success');
        this.isLoading = false;
    }

    public showModalIconCaseStudy(show:boolean = false):void {
        this.isVisibleModalIcon = show;

        if (show) {
            this.iconSelected = this.service.icon;
        }
    }

    public getIcon(icon:string):void {
        this.showModalIconCaseStudy(false);
        this.service.icon = icon;
    }

    public onChangeFile(event:any):void {
        const { target: { files }} = event;
        this.files = files;
        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(files[0]);
        //
        // fileReader.onload = function() {
        //     const img:any = document.getElementById('image_preview');
        //     img.src = this.result;
        // }
    }

    public upload():void {
        const { files, service } = this;
        const self = this;
        this.isLoading = true;

        if (files && files.length != 0) {
            const storage = firebase.storage().ref(`services/${files[0].name}`);
            const upload = storage.put(files[0]);

            upload.on(
                "state_changed",
                function progress(snapshot) {
                    // const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                function error() {
                    this.isLoading = false;
                    this.showMessage.emit({ type: 'error', message: 'Error uploading file' });
                },
                function complete() {
                    upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        service.file = downloadURL;

                        self.onSave();
                    });
                }
            );
        } else {
            this.onSave();
        }
    }

}

type MessagePopup = {
    type: string,
    message?:string,
}
