import { Component, OnInit } from '@angular/core';
import { TestimonialsService } from '../../services/testimonials.service';
import { HomeService } from '../../services/home.service';
import { Testimonials } from '../../interfaces/testimonials.interface';
import { Service } from "../../interfaces/service.interface";
import { Home } from "../../interfaces/home.interface";
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

    public dataSet = {
        title: 'GSN Research',
        subTitle: 'Your Data Collection Field Partner',
        description: 'for B2B, Industrial, and High Value B2C Data Collection',
        request: {
            title: 'Request a Quote',
            path: ['work-us'],
        },
        image: "url('/assets/img/home.png')",
    };
    public testimonials:Testimonials[] = [];
    public section5 = {
        title: '',
    };
    private interval:any;

    constructor(
        private _testimonialsService:TestimonialsService,
        public _serviceHome:HomeService,
        private router:Router,
    ) {}

    ngOnInit() {
        this.getData();
        this.getTestimonials();
    }

    public getData(): void {
        this._serviceHome.getHomeList().subscribe((data:Home) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Home = data[key];

                    this.dataSet = {
                        title: record.section1.title,
                        subTitle: record.section1.subtitle,
                        description: record.section1.description,
                        request: {
                            title: record.section1.buttonText,
                            path: ['work-us'],
                        },
                        image: `url('${record.section1.image}')`,
                    };

                    this.section5.title = record.section5.title;

                    return;
                });
            }
        });
    }

    private getTestimonials():void {
        this.testimonials = [];

        this._testimonialsService.getTestimonialsList().subscribe((data:Testimonials) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:Testimonials = data[key];

                    this.testimonials.push({
                        author: record.author,
                        employeeType: record.employeeType,
                        testimony: record.testimony,
                        icon: record.icon,
                        file: record.file,
                        key: key,
                    });
                });
            }
        });
    }

    public redirect(path:string[]) {
        if (path[0].includes('#')) {
            const element = document.querySelector(path[0]);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        } else {
            this.router.navigate(path);
        }
    }

}
