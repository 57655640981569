import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Home } from '../interfaces/home.interface';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

    public homeListURL:string = "https://geostrategy-3de81.firebaseio.com/home.json";
    public homeURL:string = "https://geostrategy-3de81.firebaseio.com/home";
    public headers = new Headers({
        'Content-Type':'application/json',
    });

    constructor(public http:Http) {}

    public newHome(data:Home):any {
        const { http, homeListURL, headers } = this;
        const record:Home = {
            section1: data.section1,
            section2: data.section2,
            section3: data.section3,
            section4: data.section4,
            section5: data.section5,
        };
        const body = JSON.stringify( record );

        return http.post( homeListURL, body, { headers }).map(res => res.json());
    }

    public editHome(data:Home, key:string):any {
        const { http, homeURL, headers } = this;
        const record:Home = {
            section1: data.section1,
            section2: data.section2,
            section3: data.section3,
            section4: data.section4,
            section5: data.section5,
        };

        const body = JSON.stringify( record );
        const url:string = `${homeURL}/${key}.json`;

        return http.put( url, body, { headers }).map(res => res.json());
    }

    public deleteHome(key:string):any {
        const { http, homeURL } = this;
        const url:string = `${homeURL}/${key}.json`;

        return http.delete(url).map(res => res.json());
    }

    public getHome(key:string):any {
        const { http, homeURL } = this;
        const url:string = `${homeURL}/${key}.json`;

        return http.get(url).map(res => res.json());
    }

    public getHomeList():any {
        const { http, homeListURL } = this;

        return http.get(homeListURL).map(res => res.json());
    }

}
