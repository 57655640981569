import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-header',
    template: `
        <article class="section-header {{ newClass }}">
          <div class="opacity"></div>
          <div class="content">
              <p class="title">{{ title }}</p>
              <nz-divider></nz-divider>
              <p>{{ subtitle }}</p>
          </div>
        </article>
    `,
    styles: [`
        .section-header {
            background-image: url('./assets/img/about1.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
            height: 300px;
            position: relative;
        }

        .max {
            height: 900px;
        }

        .section-header .opacity {
            height: 100%;
            width: 100%;
            opacity: 0.8;
            background-color: #1B1B1B;
            position: absolute;
        }

        .section-header .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .section-header p {
            margin: 0;
            font-size: 2em;
        }

        .section-header .title {
            font-size: 3em;
        }

        .section-header p, section-header .ant-divider {
            color: #FFF;
            z-index: 1;
        }

        .section-header .ant-divider {width: 80px}

        @media only screen and (max-width: 600px) {
            .section-header p {font-size: 1.3em}
            .section-header .title {font-size: 2em}
        }

    `]
})
export class SectionHeaderComponent {

    @Input()
    public title:string = "";
    @Input()
    public subtitle:string = "";
    @Input()
    public newClass:string = "";

    constructor() {}

}
