import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { About } from "../../interfaces/about.interface";
import { AboutService } from '../../services/about.service';

@Component({
    selector: 'app-about-detail',
    templateUrl: './about-detail.component.html',
    styleUrls: ['./about-detail.component.less'],
})
export class AboutDetailComponent implements OnInit {

    public defaultIcons = {
        section3: "anticon anticon-area-chart",
        section4: "far fa-check-circle",
    };
    public dataSet:About = {
        key: null,
        description: '',
        principles: [],
        helps: [],
        reasons: [],
        titleSection1: '',
        titleSection2: '',
        titleSection3: '',
        titleSection4: '',
    };

    constructor(public _service:AboutService) {}

    ngOnInit() {
        this.getData();
    }

    public getData():void {
        this._service.getAboutList().subscribe((data:About) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:About = data[key];

                    this.dataSet = {
                        key: key,
                        description: record.description,
                        principles: record.principles || [],
                        helps: record.helps || [],
                        reasons: record.reasons || [],
                        titleSection1: record.titleSection1 || '',
                        titleSection2: record.titleSection2 || '',
                        titleSection3: record.titleSection3 || '',
                        titleSection4: record.titleSection4 || '',
                    };

                    return;
                });
            }
        });
    }

    public mouseenter(event, background:string) {
        if (background && background !== '') {
            event.target.style.backgroundColor = background;
        }
    }

    public mouseleave(event, background:string) {
        if (background && background !== '') {
            event.target.style.backgroundColor = 'transparent';
        }
    }

}
