import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AboutService } from '../../../services/about.service';
import { About, Principles, Helps, Reasons } from '../../../interfaces/about.interface';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { clone, pullAt } from 'lodash';

@Component({
  selector: 'app-about-us-form',
  templateUrl: './about-us-form.component.html',
  styleUrls: ['./about-us-form.component.less']
})
export class AboutUsFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<string>();
    @Input()
    public editorConfig:AngularEditorConfig;
    @Input()
    public showSection:boolean = false;
    public showModal = {
        principles: false,
        helps: false,
        reasons: false,
    };
    public isNew:boolean = true;
    public section:string;
    public indexToEdit:number;
    public dataSet:About = {
        key: null,
        description: '',
        principles: [],
        helps: [],
        reasons: [],
        footer: '',
        titleSection1: '',
        titleSection2: '',
        titleSection3: '',
        titleSection4: '',
    };
    public principlesForm:Principles = {
        title: '',
        description: '',
        author: '',
        sentence: '',
    };
    public defaultColor:string = '#FE3B1F';
    public helpsForm:Helps = {
        title: '',
        description: '',
        icon: '',
        color: this.defaultColor,
    };
    public reasonsForm:Reasons = {
        title: '',
        description: '',
        icon: '',
        color: this.defaultColor,
    };
    // ICON
    public isVisibleModalIcon:boolean = false;
    public iconSelected:string;

    constructor(
        public _service:AboutService,
        public modal: NzModalService,
    ) {}

    ngOnInit() {
        this.getData();
    }

    public onShowModal(show:boolean=false, section:string, newEdit:boolean=true, index?:number):void {
        this.section = section;
        this.indexToEdit = index;

        if (show) {
            const { dataSet } = this;

            this.isNew = newEdit;

            if (newEdit) {
                this.clear();
            } else {
                if (section === 'principles') {
                    this.principlesForm = clone(dataSet.principles[index]);
                }
                else if (section === 'helps') {
                    this.helpsForm = clone(dataSet.helps[index]);
                }
                else if (section === 'reasons') {
                    this.reasonsForm = clone(dataSet.reasons[index]);
                }
            }
        }

        this.showModal[section] = show;
    }

    public clear():void {
        const { section } = this;

        if (section === 'principles') {
            this.principlesForm = {
                title: '',
                description: '',
                author: '',
                sentence: '',
            };
        }
        else if (section === 'helps') {
            this.helpsForm = {
                title: '',
                description: '',
                icon: '',
                color: this.defaultColor,
            };
        }
        else if (section === 'reasons') {
            this.reasonsForm = {
                title: '',
                description: '',
                icon: '',
                color: this.defaultColor,
            };
        }
    }

    public getData():void {
        this._service.getAboutList().subscribe((data:About) => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:About = data[key];

                    this.dataSet = {
                        key: key,
                        description: record.description,
                        principles: record.principles || [],
                        helps: record.helps || [],
                        reasons: record.reasons || [],
                        footer: record.footer || '',
                        titleSection1: record.titleSection1 || '',
                        titleSection2: record.titleSection2 || '',
                        titleSection3: record.titleSection3 || '',
                        titleSection4: record.titleSection4 || '',
                    };

                    return;
                });
            }
        });
    }

    public onSave():void {
        const { _service, dataSet, section } = this;

        if (!dataSet.key) {
            _service.newAbout(dataSet).subscribe(data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            _service.editAbout(dataSet, dataSet.key).subscribe(data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        }

        this.showModal[section] = false;
    }

    private saved():void {
        this.getData();
        this.clear();
        this.showMessage.emit('success');
    }

    public onSavePrinciples():void {
        const { principlesForm, isNew, indexToEdit } = this;

        if (isNew) {
            this.dataSet.principles.push({
                title: principlesForm.title || '',
                description: principlesForm.description || '',
                author: principlesForm.author || '',
                sentence: principlesForm.sentence || '',
            });
        } else {
            this.dataSet.principles[indexToEdit] = {
                title: principlesForm.title || '',
                description: principlesForm.description || '',
                author: principlesForm.author || '',
                sentence: principlesForm.sentence || '',
            };
        }

        this.onSave();
    }

    public onSaveHelps():void {
        const { helpsForm, isNew, indexToEdit, defaultColor } = this;

        if (isNew) {
            this.dataSet.helps.push({
                title: helpsForm.title || '',
                description: helpsForm.description || '',
                icon: helpsForm.icon || '',
                color: helpsForm.color || defaultColor,
            });
        } else {
            this.dataSet.helps[indexToEdit] = {
                title: helpsForm.title || '',
                description: helpsForm.description || '',
                icon: helpsForm.icon || '',
                color: helpsForm.color || defaultColor,
            };
        }

        this.onSave();
    }

    public onSaveReasons():void {
        const { reasonsForm, isNew, indexToEdit, defaultColor } = this;

        if (isNew) {
            this.dataSet.reasons.push({
                title: reasonsForm.title || '',
                description: reasonsForm.description || '',
                icon: reasonsForm.icon || '',
                color: reasonsForm.color || defaultColor,
            });
        } else {
            this.dataSet.reasons[indexToEdit] = {
                title: reasonsForm.title || '',
                description: reasonsForm.description || '',
                icon: reasonsForm.icon || '',
                color: reasonsForm.color || defaultColor,
            };
        }

        this.onSave();
    }

    public onShowConfirm(index:number): void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.onDelete(index),
            nzCancelText: 'No',
        });
    }

    public onDelete(index:number) {
        const { section } = this;

        pullAt(this.dataSet[section], [index]);

        this.onSave();
    }

    public showModalIcon(show:boolean = false):void {
        const { showModal: { helps, reasons }, dataSet, indexToEdit, isNew } = this;
        this.isVisibleModalIcon = show;

        if (show && !isNew) {
            if (helps) {
                this.iconSelected = dataSet.helps[indexToEdit].icon;
            }
            else if (reasons) {
                this.iconSelected = dataSet.reasons[indexToEdit].icon;
            }
        }
    }

    public getIcon(icon:string):void {
        const { showModal } = this;

        this.showModalIcon(false);

        if (showModal.helps) {
            this.helpsForm.icon = icon;
        }
        else if (showModal.reasons) {
            this.reasonsForm.icon = icon;
        }
    }

}
