import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaseStudiesService } from '../../../services/caseStudies.service';
import { CaseStudies } from "../../../interfaces/caseStudies.interface";
import { orderBy, cloneDeep } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-case-studies-form',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.less']
})
export class CaseStudiesFormComponent implements OnInit {

    @Output()
    public showMessage = new EventEmitter<string>();
    @Input()
    public showSection:boolean = false;
    public showModal:boolean = false;
    public isNew:boolean = true;
    public data:CaseStudies[];
    public dataSet:CaseStudies[];
    public caseStudy:CaseStudies = {
        title: '',
        type: '',
        description: '',
        nameCaseStudy: '',
        key$: '',
        process: [
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
        ],
        caseStudyItem: [
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
            {
                title: '',
                description: '',
                icon: undefined,
            },
        ],
    };
    // ICON
    public isVisibleModalIcon:boolean = false;
    public iconSection:string;
    public iconIndex:number;
    public iconSelected:string;
    public ICON_SECTIONS = {
        PROCESS: 'process',
        CASES: 'cases',
    };
    public filterType:string = 'All';

    constructor(
        public _service:CaseStudiesService,
        public modal: NzModalService,
    ) {}

    ngOnInit() {
        this.getCaseStudiesList();
    }

    public getCaseStudiesList():void {
        this.dataSet = [];

        this._service.getCaseStudies().subscribe(data => {
            if (data) {
                Object.keys(data).forEach((key:string) => {
                    const record:CaseStudies = data[key];

                    this.dataSet.push({
                        key$: key,
                        title: record.title,
                        type: record.type,
                        description: record.description,
                        nameCaseStudy: record.nameCaseStudy,
                    });
                });

                this.data = cloneDeep(this.dataSet);
                this.dataSet = orderBy(this.dataSet, ['type', 'title', 'nameCaseStudy']);
            }
        });
    }

    public onChangeType(type:string = 'All'):void {
        if (type === 'All') {
            this.dataSet = orderBy(this.data, ['type', 'title', 'nameCaseStudy']);
        }
        else {
            this.dataSet = this.data.filter(item => item.type === type);
        }
    }

    public onShowModal(show:boolean=false, newEdit:boolean=true, key$?:string):void {
        if (show) {
            this.isNew = newEdit;

            this.showModalCaseStudy(key$);
        }

        this.showModal = show;
    }

    public clearCaseStudies():void {
        this.caseStudy = {
            key$: '',
            title: '',
            type: '',
            description: '',
            nameCaseStudy: '',
            process: [
                {
                    title: 'Business challenge',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'Methodologies',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'The result',
                    description: '',
                    icon: undefined,
                },
            ],
            caseStudyItem: [
                {
                    title: 'Client description',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'Practice area',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'Geographic scope',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'Industries involved',
                    description: '',
                    icon: undefined,
                },
                {
                    title: 'Services applied',
                    description: '',
                    icon: undefined,
                },
            ],
        };
    }

    public showModalCaseStudy(key$?:string):void {
        const { isNew } = this;

        if (isNew) {
            this.clearCaseStudies();
        }
        else {
            this._service.getCaseStudy(key$).subscribe((record:CaseStudies) => {
                if (record) {
                    this.caseStudy = {
                        key$,
                        title: record.title || '',
                        type: record.type || '',
                        description: record.description || '',
                        nameCaseStudy: record.nameCaseStudy || '',
                        process: record.process || [],
                        caseStudyItem: record.caseStudyItem || [],
                    };
                }
            });
        }
    }

    public onShowConfirm(key$:string): void {
        this.modal.confirm({
            nzTitle     : 'Delete',
            nzContent   : '<strong>Are you sure delete this item?</strong>',
            nzOkText    : 'Yes',
            nzOkType    : 'danger',
            nzOnOk      : () => this.deleteCaseStudy(key$),
            nzCancelText: 'No',
        });
    }

    public deleteCaseStudy(key$:string):void {
        this._service.deleteCaseStudy(key$).subscribe(response => {
            if (response === null) {
                this.showMessage.emit('success');
                this.getCaseStudiesList();
            } else {
                this.showMessage.emit('error');
            }
        });
    }

    public onSave():void {
        const { isNew } = this;

        if (isNew) {
            this._service.newCaseStudy(this.caseStudy).subscribe( data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        } else {
            this._service.editCaseStudy(this.caseStudy, this.caseStudy.key$).subscribe( data => {
                this.saved();
            }, error => {
                this.showMessage.emit('error');
            });
        }

        this.showModal = false;
    }

    private saved():void {
        this.getCaseStudiesList();
        this.clearCaseStudies();

        this.showMessage.emit('success');
    }

    public showModalIconCaseStudy(show:boolean = false, section:string, index:number):void {
        this.isVisibleModalIcon = show;
        this.iconSection = section;
        this.iconIndex = index;

        const { ICON_SECTIONS } = this;

        if (show) {
            if (section === ICON_SECTIONS.PROCESS) {
                this.iconSelected = this.caseStudy.process[index].icon;
            }
            if (section === ICON_SECTIONS.CASES) {
                this.iconSelected = this.caseStudy.caseStudyItem[index].icon;
            }
        }

    }

    public getIcon(icon:string):void {
        const { iconIndex, iconSection, ICON_SECTIONS } = this;

        this.showModalIconCaseStudy(false, iconSection, iconIndex);

        if (iconSection === ICON_SECTIONS.PROCESS) {
            this.caseStudy.process[iconIndex].icon = icon;
        }
        if (iconSection === ICON_SECTIONS.CASES) {
            this.caseStudy.caseStudyItem[iconIndex].icon = icon;
        }
    }

}
